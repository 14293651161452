import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hero from "./components/Hero";
import FAQ from "./components/FAQ";
import AboutUs from "./components/AboutUs";
import { useState } from "react";
import { useEffect } from "react";

export default function LandingPage() {
  /* const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    const sections = [document.getElementById("home"), document.getElementById("about"), document.getElementById("faq")];
    const links = document.querySelectorAll("nav a");

    const observer = new IntersectionObserver(
      (entries) => {
        console.log("in this ", entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.id;
            setActiveLink(id);
          }
        });
      },
      { threshold: 0 } // Adjust threshold for when section is considered "in view"
    );

    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect(); // Clean up on component unmount
  }, []); */

  return (
    <>
      <CssBaseline />
      <Hero className="page" />
      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <AboutUs className="page" />
        <Divider />
        <FAQ className="page" />
        <Divider />
      </Box>
    </>
  );
}
